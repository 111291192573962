export const useDialogStore = defineStore('dialog', {
  state: () => {
    return {
      settings: {
        isOpen: false,
        currentTab: 'personal',
      },
      passwordChange: {
        isOpen: false,
      },
      userCreate: {
        isOpen: false,
        onSubmit: () => {},
      },
      userEdit: {
        isOpen: false,
        user: null,
        onSubmit: () => {},
      },
      workspaceCreate: {
        isOpen: false,
        onSubmit: () => {},
      },
      quoteEdit: {
        isOpen: false,
        id: null,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      quoteEditTitle: {
        isOpen: false,
        id: null,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      quoteEditPipedrive: {
        isOpen: false,
        id: null,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      eventChangePlan: {
        isOpen: false,
        event: null,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      plans: {
        isOpen: false,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      planEdit: {
        isOpen: false,
        id: null,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      planCreate: {
        isOpen: false,
        workspaceDomain: null,
        onSubmit: () => {},
      },
      quoteCreate: {
        isOpen: false,
        workspaceDomain: null,
        onSubmit: () => {},
      },
    };
  },

  actions: {
    open(dialogName, payload = {}) {
      this[dialogName].isOpen = true;
      this[dialogName] = {
        ...this[dialogName],
        onSubmit: () => {},
        ...payload,
      };
    },

    close(dialogName) {
      this[dialogName].isOpen = false;
    },
  },
});
